<page-header heading="Szolgáltatói regisztráció"></page-header>
<div class="center-block lp-form">
  <form [formGroup]="form">
    <div class="grid" style="margin-left:1rem;margin-right:1rem;">
      <div class="col-12 lg:col-6 lg:col-offset-3 sm:px-3 lg:px-0">
        <vedjegy-form-group label="Szolgáltató neve" [required]="true" for="szolgaltatoNev">
          <input id="szolgaltatoNev" pInputText formControlName="szolgaltatoNev" maxlength="200" required="true" type="text">
          <vedjegy-validator-message [control]="form.controls['szolgaltatoNev']"></vedjegy-validator-message>
        </vedjegy-form-group>
          <fieldset class="vedjegyfieldset grid">            
            <legend><caption style="width: 130px;">Szolgáltató címe</caption></legend>
            <div class="col-6">
              <vedjegy-form-group label="Irányítószám" 
                [labelStyle]="'white-space: nowrap'" [required]="true" for="szolgaltatoIranyitoszam">
                <input id="szolgaltatoIranyitoszam" pInputText type="text" formControlName="szolgaltatoIranyitoszam" maxlength="50" required="true"/>
                <vedjegy-validator-message [control]="form.controls['szolgaltatoIranyitoszam']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-6">
              <vedjegy-form-group label="Település"  [required]="true" for="szolgaltatoTelepules">
                <input id="szolgaltatoTelepules" pInputText type="text" formControlName="szolgaltatoTelepules" maxlength="50" required="true"/>
                <vedjegy-validator-message [control]="form.controls['szolgaltatoTelepules']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-12">
              <vedjegy-form-group label="Közterület neve" [labelStyle]="'white-space: wrap'" for="szolgaltatoKozteruletNeve" [required]="true">
                <input id="szolgaltatoKozteruletNeve" pInputText type="text" formControlName="szolgaltatoKozteruletNeve" maxlength="50" />
                <vedjegy-validator-message [control]="form.controls['szolgaltatoKozteruletNeve']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-7">
              <vedjegy-form-group label="Közterület jellege" for="kozteruletjellege">
                <p-dropdown id="kozteruletjellege" [options]="kozteruletJellegek" placeholder="kérem válasszon" [style]="{'width': '100%'}"
                  formControlName="szolgaltatoKozteruletJellegKod" [showClear]="true"></p-dropdown>
                <vedjegy-validator-message [control]="form.controls['szolgaltatoKozteruletJellegKod']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-5">
              <vedjegy-form-group label="Házszám" for="szolgaltatoHazSzam">
                <input id="szolgaltatoHazSzam" pInputText type="text" formControlName="szolgaltatoHazSzam" maxlength="50" />
                <vedjegy-validator-message [control]="form.controls['szolgaltatoHazSzam']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-4">
              <vedjegy-form-group label="Emelet" [labelStyle]="'white-space: nowrap'" for="szolgaltatoEmelet">
                <input id="szolgaltatoEmelet" pInputText type="text" formControlName="szolgaltatoEmelet" maxlength="50" />
                <vedjegy-validator-message [control]="form.controls['szolgaltatoEmelet']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-4">
              <vedjegy-form-group label="Ajtó" for="szolgaltatoAjto">
                <input id="szolgaltatoAjto" pInputText type="text" formControlName="szolgaltatoAjto" maxlength="50" />
                <vedjegy-validator-message [control]="form.controls['szolgaltatoAjto']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
            <div class="col-4">
              <vedjegy-form-group label="Helyrajzi szám" for="szolgaltatoHelyrajziSzam">
                <input id="szolgaltatoHelyrajziSzam" pInputText type="text" formControlName="szolgaltatoHelyrajziSzam" maxlength="50" />
                <vedjegy-validator-message [control]="form.controls['szolgaltatoHelyrajziSzam']">
                </vedjegy-validator-message>
              </vedjegy-form-group>
            </div>
  
          </fieldset>
        <vedjegy-form-group label="Szolgáltató központi e-mail címe" [required]="true" for="szolgaltatoEmail">
          <input id="szolgaltatoEmail" pInputText formControlName="szolgaltatoEmail" maxlength="150" required="true" type="email">
          <vedjegy-validator-message [control]="form.controls['szolgaltatoEmail']">
          </vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group label="Szolgáltató adószáma" [required]="true" for="szolgaltatoAdoszam">
          <input id="szolgaltatoAdoszam" pInputText formControlName="szolgaltatoAdoszam" maxlength="200" required="true" type="text">
          <vedjegy-validator-message [control]="form.controls['szolgaltatoAdoszam']">
          </vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group label="Szolgáltató telefonszáma" for="szolgaltatoTelefonszam">
          <input id="szolgaltatoTelefonszam" pInputText formControlName="szolgaltatoTelefonszam" maxlength="20" type="tel">
          <vedjegy-validator-message [control]="form.controls['szolgaltatoTelefonszam']">
          </vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group label="Kapcsolattartó vezetékneve" [required]="true" for="vezetekNev">
          <input id="vezetekNev" pInputText formControlName="vezetekNev" maxlength="100" required="true" type="text">
          <vedjegy-validator-message [control]="form.controls['vezetekNev']"></vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group label="Kapcsolattartó keresztneve" [required]="true" for="keresztNev">
          <input id="keresztNev" pInputText formControlName="keresztNev" maxlength="100" required="true" type="text">
          <vedjegy-validator-message [control]="form.controls['keresztNev']"></vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group label="Kapcsolattartó e-mail címe" [required]="true" for="email">
          <input id="email" pInputText formControlName="email" maxlength="150" required="true" type="email">
          <vedjegy-validator-message [control]="form.controls['email']"></vedjegy-validator-message>
        </vedjegy-form-group>
        <vedjegy-form-group [inputColClass]="'col-12'">
          <div class="p-component p-element">
              <a class="cursor-pointer" class="adatvedelmiLink" href=""
                  (click)="$event.preventDefault(); downloadFile(szolgNyilatkozatPublicId)">Regisztrációs nyilatkozat letöltése</a>
          </div>
      </vedjegy-form-group>      
        <vedjegy-form-group label="Dokumentum (pdf vagy jpg állomány)" [required]="true" for="dokumentum">
          <div class="grid">
            <div class="col-3">
              <button pButton label="Feltöltés" (click)="fileUpload.click()"></button>
            </div>
            <div class="col-9">
              <span #dok readonly="true" style="color: #495057; padding-top: 4px; display: inline-block;"></span>
              <vedjegy-validator-message [control]="form.controls['dokumentum']" ></vedjegy-validator-message>
            </div>
          </div>
        </vedjegy-form-group>
        <div class="text-center">
          <re-captcha (resolved)="onCaptchaResponse($event)" [siteKey]="captchaSiteKey"></re-captcha>
          <vedjegy-validator-message [control]="form.controls['captchaToken']"></vedjegy-validator-message>
        </div>
      </div>
      <div class="button-container">
        <button pButton label="Bezár" icon="pi pi-times-circle" (click)="back()" class="lp-button"></button>
        <button pButton label="Elküldés" (click)="save()" class="lp-button"></button>
      </div>
    </div>
  </form>
</div>
<input type="file" style="visibility: hidden;" #fileUpload accept=".pdf,.jpg,.png" (change)="onFileInput($event)">
