<div class="vedjegy-page">
    <page-header [heading]="vedjegy.nev"></page-header>
    <content-block>
        <div class="vedjegy-image-container">
            <img [src]="imageroot + vedjegy.kepId+'/asfile'" alt="{{vedjegy.nev}} megújuló védjegy ikon">
        </div>
        <div class="vedjegy-summary-container">
            {{vedjegy.rovidLeiras}}
        </div>
    </content-block>
    <content-block heading="Általános információk">
        <div [innerHtml]="vedjegy.reszletesLeiras">
        </div>
    </content-block>
</div>