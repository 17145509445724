<div class="menu-container" role="navigation">
    <p-menubar #vejdegymenu [model]="items">
        <ng-template pTemplate="start">
            <a [routerLink]="'./home'">
                <img src="assets/images/logo.png" class="mr-2 header-logo" alt="Logó - Magyarország">
            </a>
        </ng-template>
        <span>Védjegy portál</span>
        <ng-template pTemplate="end">
            <button pButton type="button" label="Bejelentkezés" icon="pi pi-user" iconPos="right"
                [routerLink]="['/bejelentkezes']" aria-label="Bejelentkezés"></button>
            <a class="accessibility-link" (click)="switchMode()">
                <img src="assets/images/accessibility.svg" alt="akadálymentesítés">
            </a>
        </ng-template>
    </p-menubar>
</div>