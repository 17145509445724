<div class="login-page">
    <page-header heading="Bejelentkezés"></page-header>
    <content-block>
        <div class="sub-heading">Ha rendelkezik felhasználóval kérem jelentkezzen be</div>
        <div class="register-container">
            <div class="register-item">
                <div class="header-image-container">
                    <img class="item-header-image" [src]="'assets/images/vedjegyImage.png'" alt="Admin felület ikon">
                </div>
                <div class="item-title">Admin felület</div>
                <div class="item-summary">Az MTÜ Zrt. és az MTMT NKft. munkatársai számára</div>
                <div class="item-button-container">
                    <button class="lp-button" (click)="adminLogin()" pButton>Admin felület belépés</button>
                </div>
            </div>
            <div class="register-item">
                <div class="header-image-container">
                    <img class="item-header-image" [src]="'assets/images/vedjegyImage.png'" alt="Szolgáltatói feület ikon">
                </div>
                <div class="item-title">Szolgáltatói felület</div>
                <div class="item-summary">Email cím - jelszó azonosítással</div>
                <div class="item-button-container">
                    <button class="lp-button" (click)="szolgaltatoiLogin()" pButton>Szolgáltatói felület belépés</button>
                </div>
            </div>
            <div class="register-item">
                <div class="header-image-container">
                    <img class="item-header-image" [src]="'assets/images/vedjegyImage.png'" alt="Szolgáltatói feület ikon">
                </div>
                <div class="item-title">E-Learning felület</div>
                <div class="item-summary">Email cím - jelszó azonosítással</div>
                <div class="item-button-container">
                    <button class="lp-button" (click)="eleraningLogin()" pButton>E-Learning felület belépés</button>
                </div>
            </div>
        </div>
    </content-block>
    <content-block>
        <div class="sub-heading">Ha nem rendelkezik felhasználóval kérem regisztráljon</div>
        <div class="register-container">
            <div class="register-item">
                <div class="header-image-container">
                    <img class="item-header-image" [src]="'assets/images/vedjegyImage.png'" alt="Szolgáltatói regisztráció ikon">
                </div>
                <div class="item-title">Szolgáltatói regisztráció</div>
                <div class="item-summary">Védjegy megszerzésére jogosult regisztráció</div>
                <div class="item-button-container">
                    <button class="lp-button" pButton [routerLink]="['/szolgaltatoiregisztracio']">Szolgáltatói regisztráció</button>
                </div>
            </div>
            <div class="register-item">
                <div class="header-image-container">
                    <img class="item-header-image" [src]="'assets/images/vedjegyImage.png'" alt="Látogatói regisztráció ikon">
                </div>
                <div class="item-title">Látogatói regisztráció</div>
                <div class="item-summary">E-learning anyagok megtekintése</div>
                <div class="item-button-container">
                    <button class="lp-button" pButton [routerLink]="['/latogatoiregisztracio']">Látogatói regisztráció</button>
                </div>
            </div>
        </div>
    </content-block>
</div>