import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app-config';

@Component({
    selector: 'bejelentkezes-page',
    templateUrl: 'bejelentkezes-page.component.html'
})

export class BejelentkezesPageComponent implements OnInit {
    constructor(   private appConfig: AppConfig) { }

    ngOnInit() { }

    adminLogin() {
        window.location.href = this.appConfig.intranetUrl;
    }

    szolgaltatoiLogin() {
        window.location.href = this.appConfig.publicUrl;
    }

    eleraningLogin() {
        window.location.href = this.appConfig.eLearningUrl;
    }

}

