import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { VedjegyDataService } from './services/vedjegy-data.service';
import { AccessibilityService } from './services/accessibility.service';
import { AppConfig } from './app-config';
import { Menubar } from 'primeng/menubar';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    constructor(
        private dataService: VedjegyDataService,
        private accessibilityService: AccessibilityService,
        private appConfig: AppConfig
    ) { }

    items: MenuItem[] = [];
    @ViewChild('vejdegymenu') vejdegymenu: Menubar;

    switchMode() {
        this.accessibilityService.switchMode();
    }

    accMode(): boolean {
        return this.accessibilityService.isInAccessibility;
    }

    ngOnInit() {
        this.accessibilityService.init();
        let newItems = [
            {
                label: 'Védjegyek',
                items: [],
            },
            {
                label: 'GYIK',
                routerLink: ['/gyik']
            },
            {
                label: 'Megszerzett védjegyek',
                routerLink: ['/megszerzettvedjegyek']
            },
            {
                label: 'Önaudit',
                routerLink: ['/onaudit']
            },
            {
                label: 'Arculati kézikönyv',
                routerLink: ['/arculatikezikonyv']
            },
            {
                label: 'E-Learning',
                url: this.appConfig.eLearningUrl
            },
            {
                label: 'Kapcsolat',
                routerLink: ['/kapcsolat']
            }
        ];
        this.items = newItems;
    }

    ngAfterViewInit() {
      this.dataService.getVedjegyList().subscribe(x => {
        let vedjegyItems = x.map(v => <MenuItem>{
            label: v.nev,
            routerLink: [`/vedjegy/${v.systemName}`]
        });

        var newItems = [...this.items];
        newItems[0].items = vedjegyItems;
        this.items = newItems;

        setTimeout(() => {
            let menuButton = this.vejdegymenu.menubutton.nativeElement;
            if (menuButton)
                menuButton.setAttribute("aria-label", "Menü");
        }, 0);
      });
    }

}
