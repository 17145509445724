import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app-config';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'oldalterkep-page',
    templateUrl: 'oldalterkep-page.component.html'
})

export class OldalterkepPageComponent implements OnInit {

    constructor(private dataService: VedjegyDataService,
        private appConfig: AppConfig
        ) { }

    elearningUrl: string;
    ngOnInit() {
        this.elearningUrl = this.appConfig.eLearningUrl;
    }


}