/**
 * @license
 * Copyright Intelliport Systems Kft. -  All Rights Reserved.
 *
 */
import { Injectable } from '@angular/core';

/**
 * Alkalmazás konfiguráció kezelés
 */
@Injectable()
export class AppConfig {
  private _buildNo: string = '24235.1';

  /**
   * Application parameters
   */
  public apiRoot: string;
  public captchaSiteKey: string;
  public eLearningUrl: string;
  public intranetUrl: string;
  public publicUrl: string;
  public get buildNo(): string
  {
    return this._buildNo === '##BUILDNO##' ? '0.0' : this._buildNo;
  }
  /**
   * Load config parameters
   *
   * @description Konfigurációs beállítások beolvasása az assets/config/ megfelelő fájlból.
   */
  public async load(): Promise<any> {
    let url = `/assets/config/vedjegyconfig.${this.buildNo}.json`;
    const r = await fetch(url);
    const config = await r.json();
    // beállítások a beolvasott config alapján
    this.apiRoot = config.apiRoot;
    this.captchaSiteKey = config.captchaSiteKey;
    this.eLearningUrl = config.eLearningUrl;
    this.intranetUrl = config.intranetUrl;
    this.publicUrl = config.publicUrl;
  }
}

