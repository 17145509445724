import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { MegujuloVedjegyDto } from 'src/app/services/api/@generated/models';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'megujulo-vedjegy-page',
    templateUrl: 'megujulo-vedjegy-page.component.html'
})

export class MegujuloVedjegyPageComponent implements OnInit {
    private vedjegyName: string;
    public vedjegy: MegujuloVedjegyDto;
    imageroot: string;
    constructor(private route: ActivatedRoute,
        private dataService: VedjegyDataService,appConfig: AppConfig) {
            this.imageroot=appConfig.apiRoot+'/api/fajl/'
         }

    ngOnInit() {
        this.route.params.subscribe(x => {
            this.vedjegyName = x['name'];
            this.dataService.getMegujuloVedjegyList().subscribe(x => {
                this.vedjegy = x.find(y => y.systemName === this.vedjegyName);
            });
        });
    }
}