<div class="content-block" [ngClass]="{'closeable': closeable, 'content-block-opened': closeable && isOpened, 'separator-image': backgroundImage}"
    [ngStyle]="style">
    <div class="block">
        <div class="content-block-header" *ngIf="heading" (click)="toggleContent()" (keyup.enter)="toggleContent()">
            <div [tabindex] ="getTabindex()" class="toggler"><span class="toggle-icon pi" [ngClass]="isOpened ? 'pi-chevron-down' : 'pi-chevron-right'"></span></div>
            <div class="heading-text">{{heading}}</div>
        </div>
        <div class="content-block-content">
            <ng-content *ngIf="!hasTextBlock"></ng-content>
            <div *ngIf="hasTextBlock && textBlock.isRichText" [innerHtml]="textBlock.text"></div>
            <div *ngIf="hasTextBlock && !textBlock.isRichText" [innerText]="textBlock.text"></div>
        </div>
    </div>
</div>