// Angular modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { RecaptchaModule } from 'ng-recaptcha';

// Primeng modules
import { MessageService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { MegaMenuModule } from 'primeng/megamenu';

//App modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMenuComponent } from './app.menu.component';
import { HomePageComponent } from './pages/home/home-page.component';
import { AppFooterComponent } from './app.footer.component';
import { ContentBlockComponent } from './components/content-block/content-block.component';
import { DummyDataService } from './services/dummy-data.service';
import { AccessibilityService } from './services/accessibility.service';
import { VedjegyPageComponent } from './pages/vedjegy/vedjegy-page.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { GyikPageComponent } from './pages/gyik/gyik-page.component';
import { MegujuloVedjegyPageComponent } from './pages/megujulo-vedjegy/megujulo-vedjegy-page.component';
import { MegszerzettVedjegyekPageComponent } from './pages/megszerzett-vedjegyek/megszerzett-vedjegyek-page.component';
import { OnauditPageComponent } from './pages/onaudit/onaudit-page.component';
import { ArculatiKezikonyvPageComponent } from './pages/arculati-kezikonyv/arculati-kezikonyv-page.component';
import { ProjektLeirasPageComponent } from './pages/projekt-leiras/projekt-leiras-page.component';
import { KapcsolatPageComponent } from './pages/kapcsolat/kapcsolat-page.component';
import { BejelentkezesPageComponent } from './pages/bejelentkezes/bejelentkezes-page.component';
import { VedjegyDataService } from './services/vedjegy-data.service';
import { TextBlockService, FajlService, ArculatiKezikonyvDokumentumService } from './services/api/@generated/services'
import { ApiConfiguration } from './services/api/@generated/api-configuration';
import { AppConfig } from './app-config';
import { SzolgaltatoiRegisztracioPageComponent } from './pages/regisztracio/szolgaltatoi-regisztracio-page.component';
import { VedjegyFormGroupComponent } from './components/form-group/vedjegy-form-group.component';
import { VedjegyTranslateLoader } from './services/vedjegy-translate-loader';
import { VedjegyValidatorMessageComponent } from './components/vedjegy-validator-message/vedjegy-validator-message.component';
import { ToastService } from './services/toast.service';
import { LatogatoiRegisztracioPageComponent } from './pages/regisztracio/latogatoi-regisztracio-page.component';
import { OnauditInditasPageComponent } from './pages/onaudit/onaudit-inditas-page.component';
import { CheckboxModule } from 'primeng/checkbox';
import { OldalterkepPageComponent } from './pages/oldalterkep/oldalterkep-page.component';
import { AdatvedelemPageComponent } from './pages/adatvedelem/adatvedelem-page.component';

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppFooterComponent,
    VedjegyFormGroupComponent,
    VedjegyValidatorMessageComponent,

    ContentBlockComponent,
    PageHeaderComponent,

    HomePageComponent,
    VedjegyPageComponent,
    MegujuloVedjegyPageComponent,
    GyikPageComponent,
    MegszerzettVedjegyekPageComponent,
    OnauditPageComponent,
    ArculatiKezikonyvPageComponent,
    ProjektLeirasPageComponent,
    KapcsolatPageComponent,
    BejelentkezesPageComponent,
    SzolgaltatoiRegisztracioPageComponent,
    LatogatoiRegisztracioPageComponent,
    OnauditInditasPageComponent,
    OldalterkepPageComponent,
    AdatvedelemPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfig]
      }
    }),

    // Primeng modules
    MenubarModule,
    MegaMenuModule,
    TabViewModule,
    ButtonModule,
    CarouselModule,
    TableModule,
    InputTextModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,

    RecaptchaModule
  ],
  providers: [
    HttpClient,
    DummyDataService,
    VedjegyDataService,
    TextBlockService,
    ArculatiKezikonyvDokumentumService,
    FajlService,
    AccessibilityService,
    MessageService,
    ToastService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppConfig, ApiConfiguration],
      multi: true,
    },
    AppConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initApp(
  appConfig: AppConfig,
  apiConfig: ApiConfiguration) {
  return async () => {
    return appConfig
      .load()
      .then(() => clearBrowserStorageOnNewVersion(appConfig.buildNo))
      .then((x) => {
        apiConfig.rootUrl = appConfig.apiRoot;
      });
  };
}

export function HttpLoaderFactory(http: HttpClient, appConfig: AppConfig) {
  return new VedjegyTranslateLoader(http, appConfig);
}

export function clearBrowserStorageOnNewVersion(version: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    let versionStorage = window.localStorage.getItem('version');
    if (versionStorage !== version) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      //clear cookies
      var cookies = document.cookie.split(";");
      cookies.forEach(cookie => {
        document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      window.localStorage.setItem('version', version);
    }
    resolve();
  });
}
