<page-header heading="Önaudit indítása"></page-header>
<div class="center-block lp-form">
    <form [formGroup]="form">
        <div class="grid" style="margin-left: 1rem;margin-right: 1rem;">
            <div class="col-12 lg:col-6 lg:col-offset-3 sm:px-3 lg:px-0">
                <vedjegy-form-group label="E-mail cím" [required]="true" for="email">
                    <input id="email" pInputText formControlName="email" maxlength="150" required="true" aria-labelledby="errormsg">
                    <vedjegy-validator-message [control]="form.controls['email']"></vedjegy-validator-message>
                </vedjegy-form-group>
                <vedjegy-form-group [inputColClass]="'col-12'">
                    <div class="p-component p-element">
                        <a class="cursor-pointer" class="adatvedelmiLink" href=""
                            (click)="$event.preventDefault(); downloadFile(adatkezelesiPublicId)">Adatvédelmi nyilatkozat</a>
                            <div class="field-checkbox" style="margin-top: 5px;">
                              <p-checkbox [binary]="true" [(ngModel)]="adatkezelesiElfogadva" inputId="adatvedelmiid"
                                [ngModelOptions]="{standalone: true}" (onChange)="elkuldesDisabled = !elkuldesDisabled"></p-checkbox>
                              <label for="adatvedelmiid" style="font-weight: unset;">Az adatvédelmi nyilatkozat tartalmát megismertem, az abban foglaltakat elfogadom</label>
                            </div>      
                    </div>
                </vedjegy-form-group>      
            </div>
            <div class="button-container">
                <button pButton label="Bezár" icon="pi pi-times-circle" (click)="back()" class="lp-button"></button>
                <button pButton label="Elküldés" (click)="save()" [disabled]="elkuldesDisabled" class="lp-button"></button>
            </div>
        </div>
    </form>
</div>