import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: 'app.footer.component.html'
})

export class AppFooterComponent implements OnInit {
    constructor() { }

    ngOnInit() { }

    public scrollToTop(): void{
        window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });
    }
}