import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FelhasznaloPreRegDto } from 'src/app/services/api/@generated/models';
import { FelhasznaloService } from 'src/app/services/api/@generated/services';
import { FormHelper } from 'src/app/shared/formhelper';
import { VedjegyValidators } from 'src/app/shared/vedjegy-validators';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { RegisterLatogatoDto } from 'src/app/services/api/@generated/models/register-latogato-dto';

@Component({
    selector: 'szolgaltaoi-regisztracio-page',
    templateUrl: 'latogatoi-regisztracio-page.component.html'
})

export class LatogatoiRegisztracioPageComponent implements OnInit {
    form: UntypedFormGroup;
    captchaSiteKey: string;

    constructor(
        private fb: UntypedFormBuilder,
        private felhasznaloService: FelhasznaloService,
        private location: Location,
        private messageService: ToastService,
        private router: Router,
        appConfig: AppConfig
    ) {
        this.captchaSiteKey = appConfig.captchaSiteKey;
    }


    get formData(): RegisterLatogatoDto {
        return this.form.value as RegisterLatogatoDto;
    }

    set formData(vedjegy: RegisterLatogatoDto) {
        this.form.reset(vedjegy);
    }

    ngOnInit() {
        this.initForm();
        this.setForm();
    }

    initForm() {
        this.form = this.fb.group({
            id: null,
            email: [null, [Validators.required, VedjegyValidators.email()]],
            keresztNev: [null, Validators.required],
            vezetekNev: [null, Validators.required],
            captchaToken: [null, Validators.required]
            //dokumentumId: [null, Validators.required]
        });
    }

    setForm() {
        let data = <RegisterLatogatoDto>{
            id: 0
        }

        this.formData = data;
    }

    save(): Promise<any> {
        if (!this.form.valid) {
            FormHelper.markAllInvalidAsDirty(this.form);
            return Promise.reject();
        } else {
            this.form.disable();
            return this.felhasznaloService
                .postApiFelhasznaloRegisterlatogato(this.formData)
                .toPromise()
                .then((x) => {
                    this.messageService.showMessage({ severity: 'success', summary: 'Sikeres regisztráció', detail: 'A látogatói regisztráció rögzítésre került, adja meg jelszavát az email-ben kapott linken' });
                    this.router.navigate(['home']);
                })
                .catch(e => {
                    this.form.enable();
                    FormHelper.handleValidationErrors(e, this.form)
                });
        }
    }

    onCaptchaResponse(response: string) {
        let captcha = this.form.controls['captchaToken'];
        captcha.setValue(response);
        captcha.updateValueAndValidity();
    }

    back(): void {
        this.location.back()
    }
}