import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StartOnauditDto } from 'src/app/services/api/@generated/models';
import { FajlService, OnauditService, RendszerparameterService } from 'src/app/services/api/@generated/services';
import { ToastService } from 'src/app/services/toast.service';
import { VedjegyValidators } from 'src/app/shared/vedjegy-validators';
import { Location } from '@angular/common'
import { FormHelper } from 'src/app/shared/formhelper';
import { Buffer } from 'buffer';

@Component({
    selector: 'onaudit-inditas-page',
    templateUrl: 'onaudit-inditas-page.component.html'
})

export class OnauditInditasPageComponent implements OnInit {
    form: UntypedFormGroup;
    private vedjegyName: string;
    adatkezelesiElfogadva: boolean = false;
    adatkezelesiPublicId: string;
    elkuldesDisabled: boolean = true;

    constructor(private fb: UntypedFormBuilder,
        private onauditService: OnauditService,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: ToastService,
        private router: Router,
        private rendszerparameterService: RendszerparameterService,
        private fajlService: FajlService
    ) { }

    get formData(): StartOnauditDto {
        return this.form.value as StartOnauditDto;
    }

    set formData(dto: StartOnauditDto) {
        this.form.reset(dto);
    }

    ngOnInit() {
        this.route.params.subscribe(x => {
            this.vedjegyName = x['name'];

            this.initForm();
            this.setForm();
        });
    }

    initForm() {
        this.form = this.fb.group({
            email: [null, [Validators.required, VedjegyValidators.email()]],
            vedjegySystemName: [null, Validators.required],
        });
    }

    setForm() {
        let data = <StartOnauditDto>{
            email: null,
            vedjegySystemName: this.vedjegyName
        }

        this.formData = data;

        this.rendszerparameterService.getApiRendszerparameterKey("AdatvedelmiNyilatkozatAnonim").subscribe(x => {
            this.adatkezelesiPublicId = x;
        })

    }

    save(): Promise<any> {
        if (!this.form.valid) {
            FormHelper.markAllInvalidAsDirty(this.form);
            return Promise.reject();
        } else {
            this.form.disable();
            return this.onauditService
                .postApiOnauditRegisterlatogato(this.formData)
                .toPromise()
                .then((x) => {
                    this.messageService.showMessage({ severity: 'success', summary: 'Sikeres önaudit indítás', detail: 'Az önaudit indítási kérés rögzítésre került, a folytatáshoz szükséges levelet hamarosan megkapja a megadott címre' });
                    this.router.navigate(['home']);
                })
                .catch(e => {
                    this.form.enable();
                    FormHelper.handleValidationErrors(e, this.form)
                });
        }
    }

    back(): void {
        this.location.back()
    }

    downloadFile(fajlPublicId: string) {
        this.fajlService.getApiFajlId(fajlPublicId)
            .subscribe(
                (fajl) => {
                    const byteArray = Buffer.from(fajl.tartalom, 'base64');
                    const blob = new Blob([byteArray]);
                    const url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = fajl.elnevezes;
                    anchor.href = url;
                    anchor.click();
                });

    }

}