import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Carousel } from 'primeng/carousel';
import { AppConfig } from 'src/app/app-config';
import { TextBlockDto, VedjegyDto } from 'src/app/services/api/@generated/models';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'onaudit-page',
    templateUrl: 'onaudit-page.component.html'
})

export class OnauditPageComponent implements OnInit {
    responsiveOptions: any[];
    vedjegyek: VedjegyDto[];
    summary: TextBlockDto;
    summary2: TextBlockDto;
    imageroot: string;
    @ViewChild('vedjegyCarousel') vedjegyCarousel: Carousel;

    constructor(
        private dataService: VedjegyDataService,
        private router: Router, appConfig: AppConfig) {
        this.imageroot = appConfig.apiRoot + '/api/fajl/'
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];

    }

    ngOnInit() {
        this.dataService.getVedjegyList().subscribe(x => this.vedjegyek = x);

        this.dataService.getTextBlocksForPage('onaudit').subscribe(x => {
            this.summary = x.find(b => b.blockName === 'summary');
            this.summary2 = x.find(b => b.blockName === 'summary2');
        });
    }

    showOnauditForm(vedjegyName: string) {
        this.router.navigate(['onaudit', 'inditas', vedjegyName]);
    }

    ngAfterViewInit() {

        let prevNavButton = this.vedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-prev");
        prevNavButton[0].setAttribute("aria-label", "Előre lapozás");

        let nextNavButton = this.vedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-next");
        nextNavButton[0].setAttribute("aria-label", "Hátra lapozás");
    }
}