import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'vedjegy-form-group',
  templateUrl: 'vedjegy-form-group.component.html',
})
export class VedjegyFormGroupComponent implements OnInit {
  @Input() label: string;
  @Input() labelClass: string;
  @Input() hidden: boolean;
  @Input() required: boolean;
  @Input() inputColClass: string;
  @Input() style: string;
  @Input() labelStyle: string;
  @Input() for: string;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {}

  getInputClass() {
    let inputColPrivate = 'col-12';
    if (this.inputColClass != null) {
      inputColPrivate = this.inputColClass;
    }
    return inputColPrivate;
  }
}
