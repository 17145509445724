import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FajlDto, FelhasznaloPreRegDto } from 'src/app/services/api/@generated/models';
import { FajlService, FelhasznaloService, RendszerparameterService } from 'src/app/services/api/@generated/services';
import { FormHelper } from 'src/app/shared/formhelper';
import { VedjegyValidators } from 'src/app/shared/vedjegy-validators';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app-config';
import { SelectItem } from 'primeng/api';
import { zip } from 'rxjs';
import { Buffer } from 'buffer';

@Component({
    selector: 'szolgaltaoi-regisztracio-page',
    templateUrl: 'szolgaltatoi-regisztracio-page.component.html'
})

export class SzolgaltatoiRegisztracioPageComponent implements OnInit {
    form: UntypedFormGroup;
    captchaSiteKey: string;
    kozteruletJellegek: SelectItem[];
    dokumentum: FajlDto;
    szolgNyilatkozatPublicId: string;
    @ViewChild("dok", { static: false }) dok: ElementRef;

    constructor(
        private fb: UntypedFormBuilder,
        private felhasznaloService: FelhasznaloService,
        private location: Location,
        private messageService: ToastService,
        private router: Router,
        appConfig: AppConfig,
        private fajlService: FajlService,
        private rendszerparameterService: RendszerparameterService

    ) {
        this.captchaSiteKey = appConfig.captchaSiteKey;
    }

    get formData(): FelhasznaloPreRegDto {
        return this.form.value as FelhasznaloPreRegDto;
    }

    set formData(vedjegy: FelhasznaloPreRegDto) {
        this.form.reset(vedjegy);
    }

    ngOnInit() {
        this.initForm();
        this.setForm();
    }

    
    initForm() {
        this.form = this.fb.group({
            id: null,
            email: [null, [Validators.required, VedjegyValidators.email()]],
            keresztNev: [null, Validators.required],
            vezetekNev: [null, Validators.required],
            szolgaltatoAdoszam: [null, Validators.required],
            szolgaltatoIranyitoszam:[null, Validators.required],
            szolgaltatoTelepules:[null, Validators.required],
            szolgaltatoKozteruletNeve: [null, Validators.required],
            szolgaltatoKozteruletJellegKod: null,
            szolgaltatoHazSzam: null,
            szolgaltatoEmelet: null,
            szolgaltatoAjto: null,
            szolgaltatoHelyrajziSzam: null,
            szolgaltatoEmail: [null, [Validators.required, VedjegyValidators.email()]],
            szolgaltatoNev: [null, Validators.required],
            szolgaltatoTelefonszam: null,
            captchaToken: [null, Validators.required],
            dokumentum: [null, Validators.required]
        });
    }

    setForm() {

        zip(
            this.felhasznaloService.getApiFelhasznaloKozteruletJellegek(),
        ).subscribe((x) => { 
            this.kozteruletJellegek = x[0].map((y) => { return { label:  y.label, value: y.value } as SelectItem; });
        });

        let data = <FelhasznaloPreRegDto>{
            id: 0
        }

        this.formData = data;

        this.rendszerparameterService.getApiRendszerparameterKey("SzolgaltatoiNyilatkozatMinta").subscribe(x => {
            this.szolgNyilatkozatPublicId = x;
        })

    }

    save(): Promise<any> {
        if (!this.form.valid) {
            FormHelper.markAllInvalidAsDirty(this.form);
            return Promise.reject();
        } else {
            this.form.disable();
            let dto = this.formData;
            dto.dokumentum = this.dokumentum;
            return this.felhasznaloService
                .postApiFelhasznaloRegisterszolgaltato(dto)
                .toPromise()
                .then((x) => {
                    this.messageService.showMessage({ severity: 'success', summary: 'Sikeres mentés', detail: 'A szolgáltatói regisztráció kérés rögzítésre került' });
                    this.router.navigate(['home']);
                })
                .catch(e => {
                    this.form.enable();
                    FormHelper.handleValidationErrors(e, this.form)
                });
        }
    }

    onCaptchaResponse(response:string) {
        let captcha = this.form.controls['captchaToken'];
        captcha.setValue(response);
        captcha.updateValueAndValidity();
    }

    back(): void {
        this.location.back()
    }

    onFileInput(event) {
        let file: File = event.target.files[0];

        if (!file) {
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
            this.dokumentum =
                {
                    elnevezes: file.name,
                    tartalom: fileReader.result.toString().split(',')[1]
                } as FajlDto;
            this.form.get('dokumentum').setValue(this.dokumentum.elnevezes);
            this.form.controls["dokumentum"].markAsTouched();
            this.dok.nativeElement.innerText = this.dokumentum.elnevezes;
        };
    }

    downloadFile(fajlPublicId: string) {
        this.fajlService.getApiFajlId(fajlPublicId)
            .subscribe(
                (fajl) => {
                    const byteArray = Buffer.from(fajl.tartalom, 'base64');
                    const blob = new Blob([byteArray]);
                    const url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = fajl.elnevezes;
                    anchor.href = url;
                    anchor.click();
                });

    }

}
