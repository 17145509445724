import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class VedjegyValidators {
    static email(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } | null => {
            if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(c.value)) {
                return { email: true };
            }
            return Validators.email(c);
        };
    }
}