import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { GyikDto, MegszerzettMegujuloVedjegyDto, MegujuloVedjegyDto, SzotarDto, TextBlockDto, VedjegyDto } from './api/@generated/models';
import { GyikService, MegszerzettMegujuloVedjegyService, MegszerzettVedjegyService, MegujuloVedjegyService, TextBlockService, VedjegyService } from './api/@generated/services';

@Injectable()
export class VedjegyDataService {

    private _textBlocks: TextBlockDto[];
    private _gyiks: GyikDto[];
    private _vedjegyek: VedjegyDto[];
    private _megujuloVedjegyek: MegujuloVedjegyDto[];
    private _megszerzettMegujuloVedjegyek: MegszerzettMegujuloVedjegyDto[];
    private _kozteruletJellegek: SzotarDto[];

    constructor(
        private textBlockService: TextBlockService,
        private gyikService: GyikService,
        private vedjegyService: VedjegyService,
        private megujuloVedjegyService: MegujuloVedjegyService,
        private megszerzettMegujuloVedjegyService: MegszerzettMegujuloVedjegyService,
        private megszerzettVedjegyService: MegszerzettVedjegyService
        ) { }

    public getTextBlocksForPage(page: string): Observable<TextBlockDto[]> {
        if (this._textBlocks) {
            let blocks = this._textBlocks.filter(x => x.pageName === page);
            return of(blocks);
        }
        else {
            return this.textBlockService.getApiTextBlockList().pipe(tap(x => this._textBlocks = x), map(x => x.filter(x => x.pageName === page)));
        }
    }

    public getGyikList(): Observable<GyikDto[]> {
        if (this._gyiks) {
            return of(this._gyiks);
        }
        else {
            return this.gyikService.getApiGyikList().pipe(tap(x => this._gyiks = x));
        }
    }

    public getVedjegyList(): Observable<VedjegyDto[]> {
        if (this._vedjegyek) {
            return of(this._vedjegyek);
        }
        else {
            return this.vedjegyService.getApiVedjegyList().pipe(tap(x => this._vedjegyek = x));
        }
    }

    public getMegujuloVedjegyList(): Observable<MegujuloVedjegyDto[]> {
        if(this._megujuloVedjegyek) {
            return of(this._megujuloVedjegyek);
        }
        else {
            return this.megujuloVedjegyService.getApiMegujuloVedjegyList().pipe(tap(x => this._megujuloVedjegyek = x));
        }
    }

    public getMegszerzettMegujuloVedjegyList(): Observable<MegszerzettMegujuloVedjegyDto[]> {
        if(this._megszerzettMegujuloVedjegyek) {
            return of(this._megszerzettMegujuloVedjegyek);
        }
        else {
            return this.megszerzettMegujuloVedjegyService.getApiMegszerzettMegujuloVedjegyList().pipe(tap(x => this._megszerzettMegujuloVedjegyek = x));
        }
    }

    public getKozteruletJellegek(): Observable<SzotarDto[]>{
        if(this._kozteruletJellegek) {
            return of(this._kozteruletJellegek);
        }
        else {
            return this.megszerzettVedjegyService.getApiMegszerzettVedjegyKozteruletTipusok().pipe(tap(x => this._kozteruletJellegek = x));
        }
    }
}