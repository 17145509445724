export { ArculatiKezikonyvDokumentumService } from './services/arculati-kezikonyv-dokumentum.service';
export { FajlService } from './services/fajl.service';
export { FelhasznaloService } from './services/felhasznalo.service';
export { GyikService } from './services/gyik.service';
export { MegszerzettMegujuloVedjegyService } from './services/megszerzett-megujulo-vedjegy.service';
export { MegszerzettVedjegyService } from './services/megszerzett-vedjegy.service';
export { MegujuloVedjegyService } from './services/megujulo-vedjegy.service';
export { OnauditService } from './services/onaudit.service';
export { RendszerparameterService } from './services/rendszerparameter.service';
export { TextBlockService } from './services/text-block.service';
export { VedjegyService } from './services/vedjegy.service';
