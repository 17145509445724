import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class AccessibilityService {

    isInAccessibility: boolean;

    switchMode() {
        this.isInAccessibility = !this.isInAccessibility;
        this.saveItems(this.isInAccessibility);
    }

    init(): void {
        this.loadFromStorage();
    }

    private storage = window.sessionStorage;
    private storageKey = 'accessabilityStatus';

    private saveItems(state: boolean) {
        this.storage.setItem(this.storageKey, JSON.stringify(state));
    }

    private loadFromStorage() {
        let state = JSON.parse(this.storage.getItem(this.storageKey));
        if (state) {
            this.isInAccessibility = state;
        }
    }
}