<div class="home-page">
    <div class="page-header">
        <div class="page-title center-block">
            <h1 class="text-center font-medium headertext">
                Magyarország kincsei
            </h1>
        </div>
        <div class="page-subtitle center-block">
            <div class="text-center font-medium headertext headersubtext">Turisztikai attrakciók minősítése</div>
        </div>
    </div>
    <content-block [ngClass]="'justifyText'" [textBlock]="vedjegyIsmerteto">
    </content-block>
    <content-block [heading]="'Folyamatábra'">
        <div class="workflow-image-container">
            <img src="assets/images/workflow.svg" style="width: 100%;" alt="A minősítési folyamat lépsei">
        </div>
    </content-block>
    <content-block [ngClass]="'justifyText'" [textBlock]="minositesiFolyamat">
    </content-block>
    <content-block [ngClass]="'divider'" [height]="'15vw'" [backgroundImage]="'section_bg.svg'"
        [backgroudPosition]="'50% 73%'">
    </content-block>
    <content-block [heading]="'Védjegyek'">
        <p-carousel #vedjegyCarousel [circular]="false" [value]="vedjegyek" [responsiveOptions]="responsiveOptions" [numVisible]="3"
            [numScroll]="1" [showIndicators]="false">
            <ng-template let-vedjegy pTemplate="item">
                <div class="vedjegy-carousel-item link-item" [routerLink]="['/vedjegy/'+vedjegy.systemName]">
                    <div class="header-image-container">
                        <img class="vedjegy-carousel-item-header-image" [src]="imageroot + vedjegy.kepId+'/asfile'"
                            alt="{{vedjegy.nev}} védejgy ikon">
                    </div>
                    <div class="vedjegy-carousel-item-title">{{vedjegy.nev}}</div>
                    <div class="vedjegy-carousel-item-summary">{{vedjegy.rovidLeiras}}</div>
                </div>
            </ng-template>
        </p-carousel>
    </content-block>
    <!-- <content-block [heading]="'Megújuló védjegyek'">
        <p-carousel #megujulovedjegyCarousel [circular]="false" [value]="megujuloVedjegyek" [responsiveOptions]="responsiveOptions" [showIndicators]="false"
            [numVisible]="3" [numScroll]="1">
            <ng-template let-megujuloVedjegy pTemplate="item">
                <div class="vedjegy-carousel-item link-item"
                    [routerLink]="['/megujulovedjegy/'+megujuloVedjegy.systemName]">
                    <div class="header-image-container">
                        <img class="vedjegy-carousel-item-header-image"
                            [src]="imageroot + megujuloVedjegy.kepId+'/asfile'"
                            alt="{{megujuloVedjegy.nev}} védjegy ikon">
                    </div>
                    <div class="vedjegy-carousel-item-title">{{megujuloVedjegy.nev}}</div>
                    <div class="vedjegy-carousel-item-summary">{{megujuloVedjegy.rovidLeiras}}</div>
                </div>
            </ng-template>
        </p-carousel>
    </content-block> -->
</div>