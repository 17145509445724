import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from '../app-config';

export class VedjegyTranslateLoader implements TranslateLoader {

    private httpLoader: TranslateHttpLoader;
    constructor(http: HttpClient, private appConfig: AppConfig) {
        this.httpLoader = new TranslateHttpLoader(http);
    }

    getTranslation(lang: string): Observable<any> {
        let version = this.appConfig.buildNo;
        let httpLoad = this.httpLoader.getTranslation(`${lang}.${version}`).pipe(map(x => {
            let jsonTranslate = x;
            return jsonTranslate;
        }));
        return httpLoad;
    }

}