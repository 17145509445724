import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VedjegyDto } from 'src/app/services/api/@generated/models';
import { FajlService, VedjegyService } from 'src/app/services/api/@generated/services';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';
import { Buffer } from 'buffer';
import { AppConfig } from 'src/app/app-config';

@Component({
    selector: 'vedjegy-page',
    templateUrl: 'vedjegy-page.component.html'
})

export class VedjegyPageComponent implements OnInit {
    private vedjegyName: string;
    public vedjegy: VedjegyDto;
    imageroot: string;

    constructor(private route: ActivatedRoute,
        private dataService: VedjegyDataService,
        private fajlService: FajlService,
        private vedjegyService: VedjegyService,
        private router: Router ,appConfig: AppConfig ) { 
            this.imageroot=appConfig.apiRoot+'/api/fajl/'
        }

    ngOnInit(): void {
        this.route.params.subscribe(x => {
            this.vedjegyName = x['name'];
            this.dataService.getVedjegyList().subscribe(x => this.vedjegy = x.find(x => x.systemName === this.vedjegyName));
        });
    }

    fileDownload(fajlPublicId: string) {
        //this.onWorkinProgress.emit(true);
        this.fajlService.getApiFajlId(fajlPublicId)
            .toPromise()
            .then((fajlResponse) => {
                const source = `data:application/;base64,${fajlResponse.tartalom}`;
                const link = document.createElement("a");
                link.href = source;
                link.download = fajlResponse.elnevezes
                link.click();

            })
            .catch((e) => {
                console.log(e)
                throw e;
            }).finally(() => {
                //this.onWorkinProgress.emit(false);
            })
    }

    showOnauditForm() {
        this.router.navigate(['onaudit', 'inditas', this.vedjegyName]);
    }

    showMegszerzettVedjegyek() {        
        this.router.navigate(['megszerzettvedjegyek', this.vedjegyName]);
    }

    downloadMinositesiUrlap() {
        this.vedjegyService.getApiVedjegyGetKriteriumRendszerSystemName(this.vedjegyName)
            .subscribe(
                (fajl) => {
                    const byteArray = Buffer.from(fajl.tartalom, 'base64');
                    const blob = new Blob([byteArray]);
                    const url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = fajl.elnevezes;
                    anchor.href = url;
                    anchor.click();
                });
    }
}