import { Component, OnInit } from '@angular/core';
import { GyikDto } from 'src/app/services/api/@generated/models/gyik-dto';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'gyik-page',
    templateUrl: 'gyik-page.component.html'
})

export class GyikPageComponent implements OnInit {
    questions: GyikDto[];
    summary: TextBlockDto = {};

    constructor(private dataService: VedjegyDataService
    ) { }

    ngOnInit() {
        this.dataService.getGyikList().subscribe(x => {
            let faqs = x;
            this.questions = faqs.sort((a, b) => {
                if (a.sorrend && b.sorrend) {
                    return a.sorrend - b.sorrend;
                }
                else if (a.sorrend) {
                    return - 1;
                }
                else if (b.sorrend) {
                    return 1;
                }
                else {
                    return a.kerdes.localeCompare(b.kerdes, 'hu');
                }
            });
        });

        this.dataService.getTextBlocksForPage('gyik').subscribe(x => {
            this.summary = x.find(b => b.blockName === 'summary');
        });
    }
}

