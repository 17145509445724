import { Observable, of } from 'rxjs';

export class DummyDataService {
    private loremRich3Paragraph = `
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sit amet diam sollicitudin,
        vulputate justo ut, tincidunt metus. Fusce vel mi ac magna pretium sodales. Pellentesque rhoncus tempus
        nisi. Curabitur non felis maximus, aliquam metus sit amet, lobortis turpis. Phasellus feugiat accumsan
        dapibus. Vivamus dolor lectus, dictum sit amet varius quis, malesuada at urna. Ut lobortis interdum
        libero, a molestie urna fringilla quis. Pellentesque consequat tincidunt vulputate. Cras non quam ac mi
        vehicula bibendum. Morbi id aliquet est, eget sodales sem. Sed ut interdum nisl, ornare tempor velit.
    </p>
    <p>
        Nunc consequat venenatis magna id tempor. Vivamus nec elementum justo. Suspendisse potenti. Morbi at sem
        nec lacus efficitur posuere vel a arcu. Aliquam vehicula eget eros sed vulputate. Fusce porta nibh
        lacus, viverra ornare tellus gravida at. Aenean elementum enim id eros laoreet placerat. Class aptent
        taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In nec libero tristique,
        pellentesque est eu, elementum sapien. Quisque in hendrerit erat. Curabitur posuere tortor laoreet velit
        elementum, in rhoncus nulla vehicula. Praesent lacus orci, facilisis nec molestie ut, condimentum eget
        neque. Maecenas eleifend efficitur arcu, fermentum tincidunt neque dignissim vitae. Proin in ipsum
        aliquam, molestie dolor vel, volutpat purus.
    </p>
    <p>
        Aliquam risus erat, gravida et tortor vel, viverra auctor justo. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Fusce tristique malesuada elit ac pharetra. Nam ullamcorper enim a augue semper tempus.
        Nullam efficitur mattis porta. Mauris iaculis sed elit non aliquam. Quisque nisi lacus, gravida in est
        eget, condimentum posuere risus. Nunc egestas diam justo, non scelerisque enim consequat quis. Praesent
        congue neque risus, ut feugiat enim semper et. In hac habitasse platea dictumst. Donec rutrum eleifend
        faucibus. Nullam sed dapibus turpis. Nullam fermentum porta nulla sed scelerisque. Sed aliquet felis a
        lorem interdum gravida.
    </p>
    `;
   

    public GetMegujuloVedjegyKategoriaList(): VedjegyKategoriaDto[] {
        return [
            {
                title: 'Megujulo 1',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo1',
                generalInformations: this.loremRich3Paragraph
            },
            {
                title: 'Megujulo 2',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo2',
                generalInformations: this.loremRich3Paragraph
            },
            {
                title: 'Megujulo 3',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo3',
                generalInformations: this.loremRich3Paragraph
            },
            {
                title: 'Megujulo 4',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo4',
                generalInformations: this.loremRich3Paragraph
            },
            {
                title: 'Megujulo 5',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo5',
                generalInformations: this.loremRich3Paragraph
            },
            {
                title: 'Megujulo 6',
                imageSource: 'vedjegy-placeholder.png',
                summary: 'Far far away, behind the word mountains, far from the countries Vokalia. Far far away, behind the word mountains',
                systemName: 'megujulo6',
                generalInformations: this.loremRich3Paragraph
            }
        ];
    }

    public GetVedjegyList(): Observable<VedjegyDto[]> {
        let vedjegyek: VedjegyDto[] = [
            {
                id: 1,
                attrakcioNev: 'Bakonyi strandfürdő',
                vedjegyKategoriaNev: 'Termálfürdők',
                cim: 'Bakonyvilmos, Kossuth utca 24.',
                lejarat: new Date(2023, 0, 13)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 2',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim2',
                lejarat: new Date(2023, 1, 28)
            },
            {
                id: 3,
                attrakcioNev: 'attrakció 3',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim3',
                lejarat: new Date(2023, 2, 30)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 4',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim4',
                lejarat: new Date(2023, 3, 12)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 5',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim5',
                lejarat: new Date(2023, 4, 7)
            }
        ];
        return of(vedjegyek);
    }

    public GetMegujuloVedjegyList(): Observable<VedjegyDto[]> {
        let vedjegyek: VedjegyDto[] = [
            {
                id: 1,
                attrakcioNev: 'Bakonyi strandfürdő',
                vedjegyKategoriaNev: 'Termálfürdők',
                cim: 'Bakonyvilmos, Kossuth utca 24.',
                lejarat: new Date(2023, 0, 13)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 2',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim2',
                lejarat: new Date(2023, 1, 28)
            },
            {
                id: 3,
                attrakcioNev: 'attrakció 3',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim3',
                lejarat: new Date(2023, 2, 30)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 4',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim4',
                lejarat: new Date(2023, 3, 12)
            },
            {
                id: 2,
                attrakcioNev: 'attrakció 5',
                vedjegyKategoriaNev: 'Védjegy1',
                cim: 'cim5',
                lejarat: new Date(2023, 4, 7)
            }
        ];
        return of(vedjegyek);
    }

    public getArculatiKezikonyvDokumentumok(): Observable<ArculatiKezikonyvDokumentumok[]> {
        return of([
            {
                id: 1,
                label: 'Kézikönyv1',
                url: ''
            },
            {
                id: 2,
                label: 'Kézikönyv2',
                url: ''
            },
            {
                id: 3,
                label: 'Kézikönyv3',
                url: ''
            }
        ]);
    }

}

export class VedjegyKategoriaDto {
    imageSource: string;
    title: string;
    summary: string;
    systemName: string;
    generalInformations: string;
    isListButtonVisible?: boolean;
}

export class VedjegyDto {
    id: number;
    attrakcioNev: string;
    vedjegyKategoriaNev: string;
    cim: string;
    lejarat: Date;
}

export class ArculatiKezikonyvDokumentumok {
    id: number;
    label: string;
    url: string;
}