import { Component } from '@angular/core';
import { AccessibilityService } from './services/accessibility.service';
import { MessageService } from 'primeng/api';
import { ToastService } from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Vedjegy-LandingPage-Web';

  constructor(toastService: ToastService,
      messageService: MessageService,
      public accessiblityService: AccessibilityService  ) {
    toastService.messages.subscribe(x => messageService.add(x));
  }
}
