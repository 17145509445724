import { Component, OnInit } from '@angular/core';
import { ArculatiKezikonyvDokumentumDto } from 'src/app/services/api/@generated/models';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { ArculatiKezikonyvDokumentumService, FajlService } from 'src/app/services/api/@generated/services';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'arculati-kezikonyv-page',
    templateUrl: 'arculati-kezikonyv-page.component.html'
})

export class ArculatiKezikonyvPageComponent implements OnInit {
    summary: TextBlockDto;
    summary2: TextBlockDto;
    dokumentumok: ArculatiKezikonyvDokumentumDto[]
    constructor(
        private akkdService: ArculatiKezikonyvDokumentumService,
        private dataService: VedjegyDataService,
        private fajlService: FajlService
    ) { }

    ngOnInit() {
        this.dataService.getTextBlocksForPage('arculatiKezikonyv').subscribe(x => {
            this.summary = x.find(b => b.blockName === 'summary');
            this.summary2 = x.find(b => b.blockName === 'summary2');
        });

        this.akkdService.getApiArculatiKezikonyvDokumentumList().subscribe(x => {
            this.dokumentumok = x;
        });
    }

    fileDownload(dokumentum: ArculatiKezikonyvDokumentumDto) {
        //this.onWorkinProgress.emit(true);
        this.fajlService.getApiFajlId(dokumentum.fajlPublicId)
            .toPromise()
            .then((fajlResponse) => {
                const source = `data:application/;base64,${fajlResponse.tartalom}`;
                const link = document.createElement("a");
                link.href = source;
                link.download = fajlResponse.elnevezes
                link.click();

            })
            .catch((e) => {
                console.log(e)
                throw e;
            }).finally(() => {
                //this.onWorkinProgress.emit(false);
            })
    }
}