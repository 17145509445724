<page-header heading="Arculati kézikönyv"></page-header>
<content-block [ngClass]="'justifyText'" [textBlock]="summary">
</content-block>
<content-block [ngClass]="'divider divider-photo'" height="20vw" backgroundImage="text-divider1.jpg"></content-block>
<content-block [ngClass]="'justifyText'" [textBlock]="summary2">
</content-block>
<content-block heading="Letölthető dokumentumok">
    <div class="content-button-holder">
        <button *ngFor="let dok of dokumentumok" type="button" pButton class="page-button lp-button" [label]="dok.dokumentumNev"
            (click)="fileDownload(dok)"></button>
    </div>
</content-block>