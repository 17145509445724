import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArculatiKezikonyvPageComponent } from './pages/arculati-kezikonyv/arculati-kezikonyv-page.component';
import { BejelentkezesPageComponent } from './pages/bejelentkezes/bejelentkezes-page.component';
import { GyikPageComponent } from './pages/gyik/gyik-page.component';
import { HomePageComponent } from './pages/home/home-page.component';
import { KapcsolatPageComponent } from './pages/kapcsolat/kapcsolat-page.component';
import { MegszerzettVedjegyekPageComponent } from './pages/megszerzett-vedjegyek/megszerzett-vedjegyek-page.component';
import { MegujuloVedjegyPageComponent } from './pages/megujulo-vedjegy/megujulo-vedjegy-page.component';
import { OldalterkepPageComponent } from './pages/oldalterkep/oldalterkep-page.component';
import { OnauditInditasPageComponent } from './pages/onaudit/onaudit-inditas-page.component';
import { OnauditPageComponent } from './pages/onaudit/onaudit-page.component';
import { ProjektLeirasPageComponent } from './pages/projekt-leiras/projekt-leiras-page.component';
import { LatogatoiRegisztracioPageComponent } from './pages/regisztracio/latogatoi-regisztracio-page.component';
import { SzolgaltatoiRegisztracioPageComponent } from './pages/regisztracio/szolgaltatoi-regisztracio-page.component';
import { VedjegyPageComponent } from './pages/vedjegy/vedjegy-page.component';
import { AdatvedelemPageComponent } from './pages/adatvedelem/adatvedelem-page.component';

const routes: Routes = [
  { path: 'home', component: HomePageComponent, title: 'Magyarország kincsei - Turisztikai attrakciók minősítése' },
  { path: 'vedjegy/:name', component: VedjegyPageComponent, title: 'Védjegy - Magyarország kincsei' },
  { path: 'megujulovedjegy/:name', component: MegujuloVedjegyPageComponent, title: 'Megújuló védjegy - Magyarország kincsei' },
  { path: 'gyik', component: GyikPageComponent, title: 'GYIK - Magyarország kincsei' },
  { path: 'megszerzettvedjegyek/:vedjegy', component: MegszerzettVedjegyekPageComponent, title: 'Megszerzett védjegyek - Magyarország kincsei' },
  { path: 'megszerzettvedjegyek', component: MegszerzettVedjegyekPageComponent, title: 'Megszerzett védjegyek - Magyarország kincsei' },
  { path: 'onaudit', component: OnauditPageComponent, title: 'Önaudit - Magyarország kincsei' },
  { path: 'arculatikezikonyv', component: ArculatiKezikonyvPageComponent, title: 'Arculati kézikönyv - Magyarország kincsei' },
  { path: 'projektleiras', component: ProjektLeirasPageComponent, title: 'Projekt leírás - Magyarország kincsei' },
  { path: 'kapcsolat', component: KapcsolatPageComponent, title: 'Kapcsolat - Magyarország kincsei' },
  { path: 'bejelentkezes', component: BejelentkezesPageComponent, title: 'Bejelentkezés - Magyarország kincsei' },
  { path: 'szolgaltatoiregisztracio', component: SzolgaltatoiRegisztracioPageComponent, title: 'Szolgáltatói regisztráció - Magyarország kincsei' },
  { path: 'latogatoiregisztracio', component: LatogatoiRegisztracioPageComponent, title: 'Látogatói regisztráció - Magyarország kincsei' },
  { path: 'onaudit/inditas/:name', component: OnauditInditasPageComponent, title: 'Önaudit indítás - Magyarország kincsei' },
  { path: 'oldalterkep', component: OldalterkepPageComponent, title: 'Oldaltérkép - Magyarország kincsei' },
  { path: 'adatvedelem', component: AdatvedelemPageComponent, title: 'Adatvédelem - Magyarország kincsei' },
  // otherwise redirect to home
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
