import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export class FormHelper {
  static markAllInvalidAsDirty(group: UntypedFormGroup): void {
    const controls = group.controls;
    for (const key of Object.keys(controls)) {
      const control = controls[key];
      if (control.invalid) {
        if (control instanceof UntypedFormControl) {
          control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
          FormHelper.markAllInvalidAsDirty(control);
        }
      }
    }
  }

  static handleValidationErrors(error: any, form: UntypedFormGroup) {
    let errors: any[] = error.error?.exception?.innerItems ?? error.error.validationErrors ?? error.error.errors;

    errors.forEach(validationElement => {
      if (validationElement.propertyName) {
        let elements = validationElement.propertyName.split(".");
        let element = elements.slice(-1)[0];
        let propertyName = element.charAt(0).toLowerCase() + element.slice(1);
        form.get(propertyName).setErrors({ propertyName: validationElement.message });
      }
    });
    throw error;
  }

  static setSzotarTranslateCode(nev: string, kod: string): string {
    if (kod)
      return `Kodok.${nev}.${kod}`;

    return '';
  }
}
