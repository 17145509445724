<div class="landing-page" [ngClass]="{'accessibility': accessiblityService.isInAccessibility}">
  <div class="landing-page-top">
    <p-toast [baseZIndex]="10001"></p-toast>
    <app-menu role="banner"></app-menu>
    <div class="landing-page-content" role="main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer role="contentinfo"></app-footer>
</div>
<div class="sz2020-logo">
  <img src="../assets/images/sz2020.png" alt="Logó - Széchenyi 2020">
</div>