import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';

@Component({
    selector: 'content-block',
    templateUrl: 'content-block.component.html'
})

export class ContentBlockComponent implements OnInit, OnChanges {
    @Input() heading: string
    @Input() backgroundImage: string;
    @Input() height: string;
    @Input() backgroudPosition: string;
    @Input() textBlock: TextBlockDto;
    @Input() closeable: boolean = false;
    @Input() tabindex: number;
    hasTextBlock: boolean;
    style: any;
    isOpened: boolean = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['textBlock'] && changes['textBlock'].currentValue) {
            this.hasTextBlock = true;
            if (this.textBlock.heading) {
                this.heading = this.textBlock.heading;
            }
        }
    }

    getTabindex() {
        if (this.tabindex)
            return "0";

        else return "";
    }

    ngOnInit() {
        this.style = {};
        if (this.backgroundImage) {
            this.style['background-image'] = `url(/assets/images/${this.backgroundImage})`;
            this.style['background-size'] = 'cover';
        }

        if (this.height) {
            this.style['height'] = this.height;
        }

        if (this.backgroudPosition) {
            this.style['background-position'] = this.backgroudPosition;
        }
    }

    toggleContent(): void {
        if (this.closeable) {
            this.isOpened = !this.isOpened;
        }
    }
}