<div class="vedjegy-page">
    <page-header [heading]="vedjegy?.nev + ' védjegy'"></page-header>
    <content-block>
        <div class="vedjegy-image-container">
            <img [src]="imageroot + vedjegy?.kepId+'/asfile'" alt="{{vedjegy?.nev}} védjegy ikon">
        </div>
        <div class="vedjegy-summary-container">
            {{vedjegy?.rovidLeiras}}
        </div>
    </content-block>
    <content-block heading="Általános információk">
        <div [innerHtml]="vedjegy?.reszletesLeiras">
        </div>
    </content-block>
    <content-block>
        <div class="content-button-holder">
            <button type="button" pButton class="page-button lp-button" label="Minősítési kézikönyv"
                (click)="fileDownload(vedjegy?.minositesiKezikonyvPublicId)"></button>
            <button type="button" pButton class="page-button lp-button" label="Védjegyszabályzat"
                (click)="fileDownload(vedjegy?.vedjegyszabalyzatPublicId)"></button>
            <button type="button" pButton class="page-button lp-button"
                label="Kritériumrendszer megtekintése" (click)="downloadMinositesiUrlap()"></button>
            <button type="button" pButton class="page-button lp-button" label="ÁSZF letöltés"
                (click)="fileDownload(vedjegy?.aszfPublicId)"></button>
            <button type="button" pButton class="page-button lp-button" label="Önaudit indítása"
                (click)="showOnauditForm()"></button>
            <button *ngIf="vedjegy?.portalonListazhato" type="button" pButton class="page-button lp-button"
                label="Megszerzett védjegyek" (click)="showMegszerzettVedjegyek()"></button>
        </div>
    </content-block>
</div>