import { Component, OnInit, ViewChild } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { AppConfig } from 'src/app/app-config';
import { MegujuloVedjegyDto, VedjegyDto } from 'src/app/services/api/@generated/models';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'home-page',
    templateUrl: 'home-page.component.html'
})

export class HomePageComponent implements OnInit {
    responsiveOptions: any[];
    vedjegyek: VedjegyDto[];
    megujuloVedjegyek: MegujuloVedjegyDto[];
    vedjegyIsmerteto: TextBlockDto;
    minositesiFolyamat: TextBlockDto;
    imageroot: string;
    @ViewChild('vedjegyCarousel') vedjegyCarousel: Carousel;
    // @ViewChild('megujulovedjegyCarousel') megujulovedjegyCarousel: Carousel;

    constructor(private dataService: VedjegyDataService,appConfig: AppConfig) {
       this.imageroot=appConfig.apiRoot+'/api/fajl/'
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    ngOnInit() {
        this.dataService.getMegujuloVedjegyList().subscribe(x => this.megujuloVedjegyek = x);
        this.dataService.getVedjegyList().subscribe(x => this.vedjegyek = x);
        this.dataService.getTextBlocksForPage('home').subscribe(x => {
            this.vedjegyIsmerteto = x.find(b => b.blockName === 'vedjegyIsmerteto');
            this.minositesiFolyamat = x.find(b => b.blockName === 'minositesiFolyamat');
        });

    }

    ngAfterViewInit() {
        let prevNavButton = this.vedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-prev");
        prevNavButton[0].setAttribute("aria-label","Előre lapozás");

        let nextNavButton = this.vedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-next");
        nextNavButton[0].setAttribute("aria-label","Hátra lapozás");

        // let prevNavButton2 = this.megujulovedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-prev");
        // prevNavButton2[0].setAttribute("aria-label","Előre lapozás");

        // let nextNavButton2 = this.megujulovedjegyCarousel.el.nativeElement.getElementsByClassName("p-ripple p-carousel-next");
        // nextNavButton2[0].setAttribute("aria-label","Hátra lapozás");

   }
}

