import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelyszinDto, MegszerzettMegujuloVedjegyDto, MegszerzettVedjegyDto, SzotarDto } from 'src/app/services/api/@generated/models';
import { MegszerzettVedjegyService } from 'src/app/services/api/@generated/services';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';
import { Table } from 'primeng/table'
import { DateTime } from 'luxon'
import { filter } from 'rxjs';
@Component({
    selector: 'megszerzett-vedjegyek-page',
    templateUrl: 'megszerzett-vedjegyek-page.component.html'
})

export class MegszerzettVedjegyekPageComponent implements OnInit {
    @ViewChild(('dt')) dt: Table;
    // @ViewChild(('megujulotable')) megujulotable: Table;
    megszerzettVedjegyek: MegszerzettMegujuloVedjegyDto[];
    megszerzettMegujuloVedjegyek: MegszerzettMegujuloVedjegyDto[];
    kozteruletJellegek: SzotarDto[];

    constructor(
        private vedjegyDataService: VedjegyDataService,
        private megszerzettVedjegyService: MegszerzettVedjegyService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.vedjegyDataService.getKozteruletJellegek().subscribe(x => {
            this.kozteruletJellegek = x;
            this.megszerzettVedjegyService.getApiMegszerzettVedjegyList().subscribe(x => {
                this.megszerzettVedjegyek = x.map(e => <MegszerzettMegujuloVedjegyDto>{
                    megujuloVedjegyNev: e.vedjegy,
                    helyszinCim: this.getCim(e.helyszin),
                    attrakcio: e.helyszin.nev,
                    lejarat: DateTime.fromISO(e.lejarat).toFormat('yyyy.MM.dd.')
                });
                
                this.route.params.subscribe(x => {
                    let vedjegyName = x['vedjegy'];
                    this.vedjegyDataService.getVedjegyList().subscribe(vl => {
                        let vedjegy = vl.find(v => v.systemName === vedjegyName);
                        this.filterVedjegyek(vedjegy?.nev);
                    });
                });
            });
        });


        this.vedjegyDataService.getMegszerzettMegujuloVedjegyList().subscribe(x => {
            this.megszerzettMegujuloVedjegyek = x.map(y => {
                let z = { ...y };
                z.lejarat = DateTime.fromISO(z.lejarat).toFormat('yyyy.MM.dd.');
                return z;
            });
        });
    }

    public getCim(helyszin: HelyszinDto): string {
        let kozteruletJellege = this.kozteruletJellegek.find(k => k.kod === helyszin.kozteruletJellegKod)?.nev;
        let cim = '';
        if (helyszin.iranyitoszam) {
            cim += helyszin.iranyitoszam;
        }
        if (helyszin.telepules) {
            if (cim.length > 0) {
                cim += ' ';
            }
            cim += helyszin.telepules
        }
        if (helyszin.kozteruletNeve) {
            if (cim.length > 0) {
                cim += ', ';
            }
            cim += helyszin.kozteruletNeve
        }
        if (kozteruletJellege) {
            if (cim.length > 0) {
                cim += ' ';
            }
            cim += kozteruletJellege
        }
        if (helyszin.hazSzam) {
            if (cim.length > 0) {
                cim += ' ';
            }
            cim += helyszin.hazSzam
        }
        return cim;
    }

    public filterVedjegyek(filterValue: string) {
        if (filterValue)
            this.dt.filter(filterValue, 'megujuloVedjegyNev', 'contains')
    }

    ngAfterViewInit() {
        const table = this.dt.el.nativeElement.querySelector('table');
        table.setAttribute('summary', 'Megszerzett védjegyek');
        // const megujulotable = this.megujulotable.el.nativeElement.querySelector('table');
        // megujulotable.setAttribute('summary', 'Megújuló védjegyek');
    }
}
