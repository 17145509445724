import { Component, OnInit } from '@angular/core';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'projekt-leiras-page',
    templateUrl: 'projekt-leiras-page.component.html'
})

export class ProjektLeirasPageComponent implements OnInit {
    summary: TextBlockDto;
    summary2: TextBlockDto;

    constructor(private dataService: VedjegyDataService) { }

    ngOnInit() {
        this.dataService.getTextBlocksForPage('projektLeiras').subscribe(x => {
            this.summary = x.find(b => b.blockName === 'summary');
            this.summary2 = x.find(b => b.blockName === 'summary2');
        });
    }
}