<div class="megszerzett-vedjegyek-page">
    <page-header heading="Megszerzett védjegyek"></page-header>
    <content-block>
        <p-table #dt [value]="megszerzettVedjegyek" styleClass="p-datatable-sm p-datatable-gridlines"
            responsiveLayout="stack" breakpoint="400px">
            <caption>Megszerzett védjegyek</caption>
            <ng-template pTemplate="header">
                <tr class="table-names">
                    <th pSortableColumn="attrakcio">Attrakció<p-sortIcon field="attrakcio" class="sort-icon">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="megujuloVedjegyNev">Védjegy<p-sortIcon field="megujuloVedjegyNev"
                            class="sort-icon"></p-sortIcon>
                    </th>
                    <th pSortableColumn="helyszinCim">Cím<p-sortIcon field="helyszinCim" class="sort-icon"></p-sortIcon>
                    </th>
                    <th pSortableColumn="lejarat">Lejárat<p-sortIcon field="lejarat" class="sort-icon"></p-sortIcon>
                    </th>
                </tr>
                <tr class="table-filters">
                    <th>
                        <p-columnFilter type="text" field="attrakcio" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="megujuloVedjegyNev" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="helyszinCim" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="lejarat" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vedjegy>
                <tr>
                    <td>{{vedjegy.attrakcio}}</td>
                    <td>{{vedjegy.megujuloVedjegyNev}}</td>
                    <td>{{vedjegy.helyszinCim}}</td>
                    <td>{{vedjegy.lejarat}}</td>
                </tr>
            </ng-template>
        </p-table>
    </content-block>
    <!-- <content-block>
        <div class="secondary-header">Megújuló védjegyek</div>
        <p-table #megujulotable [value]="megszerzettMegujuloVedjegyek" styleClass="p-datatable-sm p-datatable-gridlines"
            responsiveLayout="stack" breakpoint="400px">
            <caption>Megújuló védjegyek</caption>
            <ng-template pTemplate="header" let-columns>
                <tr class="table-names">
                    <th pSortableColumn="attrakcio">Attrakció<p-sortIcon field="attrakcio" class="sort-icon">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="megujuloVedjegyNev">Védjegy<p-sortIcon field="megujuloVedjegyNev"
                            class="sort-icon"></p-sortIcon>
                    </th>
                    <th pSortableColumn="helyszinCim">Cím<p-sortIcon field="helyszinCim" class="sort-icon"></p-sortIcon>
                    </th>
                    <th pSortableColumn="lejarat">Lejárat<p-sortIcon field="lejarat" class="sort-icon"></p-sortIcon>
                    </th>
                </tr>
                <tr class="table-filters">
                    <th>
                        <p-columnFilter type="text" field="attrakcio" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="megujuloVedjegyNev" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="helyszinCim" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="lejarat" [showMenu]="false" matchMode="contains"
                            [showClearButton]="false" placeholder="Keresés">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <input type="search"
                                  pInputText
                                  [ngModel]="value"
                                  (ngModelChange)="filter($event)"
                                  class="p-inputtext"
                                  placeholder="Keresés">
                            </ng-template>
                        </p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vedjegy>
                <tr>
                    <td>{{vedjegy.attrakcio}}</td>
                    <td>{{vedjegy.megujuloVedjegyNev}}</td>
                    <td>{{vedjegy.helyszinCim}}</td>
                    <td>{{vedjegy.lejarat}}</td>
                </tr>
            </ng-template>
        </p-table>
    </content-block> -->
</div>