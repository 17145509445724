import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'vedjegy-validator-message',
    template: `
    <div *ngIf="this.control.invalid && (this.control.dirty || this.control.touched)"
      class="ui-message ui-messages-error ui-corner-all">
      <div id="errormsg">
        <p *ngFor="let message of messages" style="margin: 0; padding-bottom: 3px;" role="alert">{{ message }}</p>
      </div>
    </div>
    `
})

export class VedjegyValidatorMessageComponent implements OnInit {
    @Input() control: AbstractControl;
    hasError: boolean;
    messages: string[];

    constructor(private translateService: TranslateService) {
      }

      ngOnInit(): void {
        this.checkErrors();
        this.control.statusChanges.subscribe((x) => this.checkErrors());
      }

      async checkErrors() {
        this.clearMessage();
        if (this.control.invalid) {
          const errors = this.control.errors;
          for (const errorProp in errors) {
            if (errors.hasOwnProperty(errorProp)) {
              if (typeof errors[errorProp] === 'string') {
                let message = errors[errorProp];

                let translationKey = `ValidaciosUzenet.${message}`;
                let translatedMessage = await this.translateService.get(translationKey).toPromise();
                if (translatedMessage !== translationKey)
                  message = translatedMessage;

                this.appendMessage(message);
              } else {
                const newMessage = await this.translateService.get(`ValidaciosUzenet.${errorProp}`, errors[errorProp]).toPromise();
                this.appendMessage(newMessage);
              }
            }
          }
        }
      }

      private clearMessage() {
        this.messages = [];
      }

      private appendMessage(message: string) {
        this.messages.push(message);
      }
}
