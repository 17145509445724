import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable()
export class ToastService{
    messages = new Subject<Message>();

    showMessage(message: Message) {
        this.messages.next(message);
        
    }
}