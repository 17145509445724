/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FelhasznaloPreRegDto } from '../models/felhasznalo-pre-reg-dto';
import { RegisterLatogatoDto } from '../models/register-latogato-dto';
import { SelectItemDto } from '../models/select-item-dto';
@Injectable({
  providedIn: 'root',
})
class FelhasznaloService extends __BaseService {
  static readonly postApiFelhasznaloRegisterszolgaltatoPath = '/api/Felhasznalo/registerszolgaltato';
  static readonly postApiFelhasznaloRegisterlatogatoPath = '/api/Felhasznalo/registerlatogato';
  static readonly getApiFelhasznaloKozteruletJellegekPath = '/api/Felhasznalo/KozteruletJellegek';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiFelhasznaloRegisterszolgaltatoResponse(body?: FelhasznaloPreRegDto): __Observable<__StrictHttpResponse<FelhasznaloPreRegDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Felhasznalo/registerszolgaltato`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FelhasznaloPreRegDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiFelhasznaloRegisterszolgaltato(body?: FelhasznaloPreRegDto): __Observable<FelhasznaloPreRegDto> {
    return this.postApiFelhasznaloRegisterszolgaltatoResponse(body).pipe(
      __map(_r => _r.body as FelhasznaloPreRegDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiFelhasznaloRegisterlatogatoResponse(body?: RegisterLatogatoDto): __Observable<__StrictHttpResponse<RegisterLatogatoDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Felhasznalo/registerlatogato`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegisterLatogatoDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiFelhasznaloRegisterlatogato(body?: RegisterLatogatoDto): __Observable<RegisterLatogatoDto> {
    return this.postApiFelhasznaloRegisterlatogatoResponse(body).pipe(
      __map(_r => _r.body as RegisterLatogatoDto)
    );
  }

  /**
   * @return Success
   */
  getApiFelhasznaloKozteruletJellegekResponse(): __Observable<__StrictHttpResponse<Array<SelectItemDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Felhasznalo/KozteruletJellegek`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SelectItemDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiFelhasznaloKozteruletJellegek(): __Observable<Array<SelectItemDto>> {
    return this.getApiFelhasznaloKozteruletJellegekResponse().pipe(
      __map(_r => _r.body as Array<SelectItemDto>)
    );
  }
}

module FelhasznaloService {
}

export { FelhasznaloService }
