<page-header heading="Oldaltérkép"></page-header>
<ul class="sitemapcontainer">
    <li class="sitemapelement"><a [routerLink]="['/home']">Kezdőlap</a></li>
    <li class="sitemapelement"><a [routerLink]="['/gyik']">GYIK</a></li>
    <li class="sitemapelement"><a [routerLink]="['/megszerzettvedjegyek']">Megszerzett védjegyek</a></li>
    <li class="sitemapelement"><a [routerLink]="['/onaudit']">Önaudit</a></li>
    <li class="sitemapelement"><a [routerLink]="['/arculatikezikonyv']">Arculati kézikönyv</a></li>
    <li class="sitemapelement"><a [routerLink]="['/projektleiras']">Projekt leírás</a></li>
    <li class="sitemapelement"><a [routerLink]="['/adatvedelem']">Adatvédelem</a></li>
    <li class="sitemapelement"><a [routerLink]="['/kapcsolat']">Kapcsolat</a></li>
    <li class="sitemapelement"><a [routerLink]="['/bejelentkezes']">Bejelentkezés</a></li>
    <li class="sitemapelement"><a [routerLink]="['/szolgaltatoiregisztracio']">Szolgáltatói regisztráció</a></li>
    <li class="sitemapelement"><a [routerLink]="['/latogatoiregisztracio']">Látogatói regisztráció</a></li>
    <li class="sitemapelement"><a [href]="[elearningUrl]">E-Learning</a></li>
</ul>
