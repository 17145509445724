import { Component, OnInit } from '@angular/core';
import { TextBlockDto } from 'src/app/services/api/@generated/models/text-block-dto';
import { VedjegyDataService } from 'src/app/services/vedjegy-data.service';

@Component({
    selector: 'kapcsolat-page',
    templateUrl: 'kapcsolat-page.component.html'
})

export class KapcsolatPageComponent implements OnInit {
    summary: TextBlockDto;

    constructor(private dataService: VedjegyDataService) { }

    ngOnInit() {
        this.dataService.getTextBlocksForPage('kapcsolat').subscribe(x => {
            this.summary = x.find(b => b.blockName === 'summary');
        });
    }
}