<div class="onaudit-page">
    <page-header heading="Önaudit ismertető"></page-header>
    <content-block [ngClass]="'justifyText'" [textBlock]="summary">
    </content-block>
    <content-block [ngClass]="'divider divider-photo'" height="20vw" backgroundImage="text-divider1.jpg">
    </content-block>
    <content-block [ngClass]="'justifyText'" [textBlock]="summary2">
    </content-block>
    <content-block heading="Védjegyek">
        <p-carousel #vedjegyCarousel [circular]="false" [value]="vedjegyek" [responsiveOptions]="responsiveOptions" [numVisible]="3"
            [numScroll]="1" [showIndicators]="false">
            <ng-template let-vedjegy pTemplate="item">
                <div tabindex="0" class="vedjegy-carousel-item">
                    <div class="header-image-container">
                        <img class="vedjegy-carousel-item-header-image"
                            [src]="imageroot + vedjegy.kepId+'/asfile'" alt="{{vedjegy.nev}} védejgy ikon">
                    </div>
                    <div class="vedjegy-carousel-item-title link-item" [routerLink]="['/vedjegy/'+vedjegy.systemName]">
                        {{vedjegy.nev}}</div>
                    <div class="vedjegy-carousel-item-summary">{{vedjegy.rovidLeiras}}</div>
                    <div class="vedjegy-carousel-item-button-container">
                        <button class="lp-button" pButton (click)="showOnauditForm(vedjegy.systemName)">Önaudit
                            indítása</button>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </content-block>
</div>