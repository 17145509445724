<page-header heading="Látogatói regisztráció"></page-header>
<div class="center-block lp-form">
    <form [formGroup]="form">
        <div class="grid" style="margin-left: 1rem;margin-right: 1rem;">
            <div class="col-12 lg:col-6 lg:col-offset-3 sm:px-3 lg:px-0">
                <vedjegy-form-group label="Vezetéknév" [required]="true" for="vezetekNev">
                    <input id="vezetekNev" pInputText formControlName="vezetekNev" maxlength="100" required="true" type="text">
                    <vedjegy-validator-message [control]="form.controls['vezetekNev']"></vedjegy-validator-message>
                </vedjegy-form-group>
                <vedjegy-form-group label="Keresztnév" [required]="true" for="keresztNev">
                    <input id="keresztNev" pInputText formControlName="keresztNev" maxlength="100" required="true" type="text">
                    <vedjegy-validator-message [control]="form.controls['keresztNev']"></vedjegy-validator-message>
                </vedjegy-form-group>
                <vedjegy-form-group label="E-mail cím" [required]="true" for="email">
                    <input id="email" pInputText formControlName="email" maxlength="150" required="true" type="email">
                    <vedjegy-validator-message [control]="form.controls['email']"></vedjegy-validator-message>
                </vedjegy-form-group>
                <!-- <vedjegy-form-group label="Dokumentum" [required]="true">
                <input pInputText formControlName="dokumentumId">
                <vedjegy-validator-message [control]="form.controls['dokumentumId']"></vedjegy-validator-message>
            </vedjegy-form-group> -->
                <div class="text-center">
                    <re-captcha (resolved)="onCaptchaResponse($event)" [siteKey]="captchaSiteKey"></re-captcha>
                    <vedjegy-validator-message [control]="form.controls['captchaToken']"></vedjegy-validator-message>
                </div>
            </div>
            <div class="button-container">
                <button pButton label="Bezár" icon="pi pi-times-circle" (click)="back()" class="lp-button"></button>
                <button pButton label="Elküldés" (click)="save()" class="lp-button"></button>
            </div>
        </div>
    </form>
</div>