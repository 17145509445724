<div class="footer-container">
    <div class="footer-content center-block">
        <div class="footer-content-left">
            <div>
                <img class="footer-logo" src="assets/images/logo.png" alt="Logó - Magyarország">
            </div>
            <div>
                <div>© 2023 Magyar Turisztikai Minőségtanúsító Testület NKft</div>
                <div>All rights reserved</div>
            </div>
            <div class="footer-menu-item">
                <div><button pButton [routerLink]="['/adatvedelem']">Adatvédelem</button></div>
            </div>
            <div class="footer-menu-item">
                <div><button pButton [routerLink]="['/projektleiras']">Projekt leírása</button></div>
            </div>
            <div class="footer-menu-item">
                <div><button pButton [routerLink]="['/oldalterkep']">Oldaltérkép</button></div>
            </div>
        </div>
        <div>
            <button pButton label="Vissza az oldal tetejére" icon="pi pi-chevron-up" iconPos="right"
                (click)="scrollToTop()"></button>
        </div>
    </div>
    <div class="footer-sz2020-logo">
        <img src="assets/images/sz2020.png" alt="Logó - Széchenyi 2020">
    </div>
</div>